@tailwind base;
@tailwind components;
@tailwind utilities;



p {
  font-weight: 100;
  font-size: 25px;
 }

.informations, .carte, .descriptions {
margin-bottom: 75px;

 }
 
 #customers {
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #025637;
  color: white;
}

input[type=text], select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 15px;
  margin-bottom: 16px;
  resize: vertical;
}

textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 15px;
  margin-bottom: 16px;
  resize: vertical;
  font-family: Helvetica;
  font-size: 14px;

}

input[type=submit] {
  background-color: #025637;
  color: white;
  padding: 13px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #40ba8d;
}

.contact {
  border-radius: 5px;
} 